import PropTypes from 'prop-types'
import React from 'react'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'

import logo from '../../../static/media/sedicii-logo.png'

const LoginPanel = ({ show, children }) => (
  <Box
    sx={ {
      display: 'flex',
      justifyContent: 'center',
      margin: '4rem',
      marginTop: '10rem'
    } }
  >
    <Box sx={ { width: { xs: '90%', sm: '50%', md: '30%' } } }>
      <Fade in={ show } timeout={ 1000 }>
        <Paper sx={ { padding: '15pt' } }>
          <Box sx={ { display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
            <Box sx={ { padding: '1rem', height: '5rem', textAlign: 'center' } }>
              <img alt='Sedicii Logo' src={ logo } style={ { height: '100%' } } />
            </Box>
            {children}
          </Box>
        </Paper>
      </Fade>
    </Box>
  </Box>
)

LoginPanel.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired
}

export default LoginPanel
