import { stringify } from 'query-string'
import { getToken } from './auth-util'
import { fetchUtils } from 'react-admin'
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE
} from 'react-admin'

/**
 * Maps react-admin queries to the Sedicii API
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

  const authenticatedClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new window.Headers({ Accept: 'application/json' })
    }
    options.headers.set('Authorization', `Bearer ${ getToken() }`)
    return httpClient(url, options)
  }

  const convertRESTRequestToHTTP = (type, resource, params) => {
    let url = ''
    const options = {}
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
          ...params.filter,
          sort: `${ field },${ order.toLowerCase() }`,
          offset: (page - 1) * perPage,
          limit: perPage
        }
        url = `${ apiUrl }/${ resource }?${ stringify(query) }`
        break
      }
      case GET_ONE:
        url = `${ apiUrl }/${ resource }/${ params.id }`
        break
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
          ...params.filter,
          [ params.target ]: params.id,
          _sort: field,
          _order: order,
          _start: (page - 1) * perPage,
          _end: page * perPage
        }
        url = `${ apiUrl }/${ resource }?${ stringify(query) }`
        break
      }
      case UPDATE:
        url = `${ apiUrl }/${ resource }/${ params.id }`
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        break
      case CREATE:
        url = `${ apiUrl }/${ resource }`
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        break
      case DELETE:
        url = `${ apiUrl }/${ resource }/${ params.id }`
        options.method = 'DELETE'
        break
      default:
        throw new Error(`Unsupported fetch action type ${ type }`)
    }
    return { url, options }
  }

  const convertHTTPResponseToREST = (response, type, resource, params) => {
    const { json } = response
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE: {
        if (!json.page || typeof json.page.total === 'undefined') {
          throw new Error('Unexpected data received')
        }
        const { offset, limit } = json.page
        return {
          data: json.content.map((item, index) => ({ ...item, id: index + (offset - 1) * limit })),
          total: json.page.total
        }
      }
      case CREATE:
        return { data: { ...params.data, id: json.id } }
      default:
        return { data: json }
    }
  }

  return (type, resource, params) => {
    if (type === GET_MANY) {
      return Promise.all(
        params.ids.map(id => authenticatedClient(`${ apiUrl }/${ resource }/${ id }`))
      ).then(responses => ({
        data: responses.map(response => response.json)
      }))
    }
    const { url, options } = convertRESTRequestToHTTP(type, resource, params)
    return authenticatedClient(url, options).then(response =>
      convertHTTPResponseToREST(response, type, resource, params)
    )
  }
}
