import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Webcam from 'react-webcam'

const styles = {
  button: {
    marginTop: '10pt'
  },
  camera: {
    padding: '10pt'
  }
}

const Image = ({ item }) => {
  const [ picture, setPicture ] = useState(item.value || null)
  const [ userMediaError, setUserMediaError ] = useState(null)
  const webcamRef = useRef(null)

  const takePicture = () => {
    const capturedPicture = webcamRef.current.getScreenshot()
    setPicture(capturedPicture)
  }

  const onUserMediaError = (error) => {
    let errorMessage = ''
    if (error.name === 'ConstraintNotSatisfiedError') {
      errorMessage = 'Not proper video source found. Use another device to make this process'
    } else if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
      errorMessage = 'Allow the web page to use the WebCam to be able to take a picture'
    } else if (error.name === 'NotFoundError') {
      errorMessage = 'Not video source found. Use another device to make this process'
    } else if (error.name === 'NotReadableError') {
      errorMessage = 'Camera already in usage, please ensure that not another program is using the camera'
    } else {
      errorMessage = 'Unknown Video Error. Use another device to make this process'
    }
    setUserMediaError(errorMessage)
  }

  const discardPicture = () => {
    setPicture(null)
  }

  const renderWebCam = () => (
    <Box item xs={ 12 }>
      <div onClick={ takePicture }>
        <Webcam
          audio={ false }
          ref={ webcamRef }
          screenshotFormat='image/png'
          onUserMediaError={ onUserMediaError }
        />
      </div>
      <div style={ styles.button }>
        <Button variant='contained' color='primary' onClick={ takePicture }>Take Picture</Button>
      </div>
    </Box>
  )

  const renderPicture = () => (
    <Box item xs={ 12 }>
      <div onClick={ discardPicture }>
        <img src={ picture } alt='captured' />
      </div>
      <div style={ styles.button }>
        <Button variant='contained' color='secondary' onClick={ discardPicture }>Discard Picture</Button>
      </div>
    </Box>
  )

  const renderError = () => (
    <Box item xs={ 12 }>
      <div>
        <p>{userMediaError}</p>
      </div>
    </Box>
  )

  return (
    <Box item xs={ 12 }>
      <Paper>
        <div style={ styles.camera }>
          {userMediaError ? renderError() : (picture ? renderPicture() : renderWebCam())}
        </div>
      </Paper>
    </Box>
  )
}

Image.propTypes = {
  item: PropTypes.object.isRequired
}

export default Image
