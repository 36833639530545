import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'

const DATE_FORMAT = 'YYYY-MM-DD'

const DateInput = ({ item }) => {
  const [ selectedDate, setSelectedDate ] = useState(item.value ? moment(item.value, DATE_FORMAT) : null)
  const [ error, setError ] = useState(false)

  const handleDateChange = (date) => {
    setSelectedDate(date)
    setError(!date)
  }

  return (
    <LocalizationProvider dateAdapter={ AdapterMoment }>
      <DatePicker
        label={ item.label }
        value={ selectedDate }
        onChange={ (date) => handleDateChange(date) }
        renderInput={ (params) => (
          <TextField
            { ...params }
            error={ error }
            helperText={ error ? 'Invalid Date' : '' }
            fullWidth
          />
        ) }
      />
    </LocalizationProvider>
  )
}

DateInput.propTypes = {
  item: PropTypes.object.isRequired
}

export default DateInput
