import React from 'react'
import { connect } from 'react-redux'

import Dashboard from './Dashboard'

const Main = () => {
  return (
    <div>
      <Dashboard />
    </div>
  )
}

export default connect((state) => ({
  auth: state.auth
}))(Main)
