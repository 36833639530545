import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'

import configuration from '../configuration'
import { updateRecaptcha } from '../actions'

const Recaptcha = ({ dispatch, onChange }) => {
  const recaptchaRef = useRef(null)

  const onRecaptchaChange = (response) => {
    dispatch(updateRecaptcha(response))
    onChange()
  }

  return (
    <ReCAPTCHA
      ref={ recaptchaRef }
      sitekey={ configuration.RECAPTCHA_SITE_KEY }
      onChange={ onRecaptchaChange }
      style={ { marginBottom: 24 } }
    />
  )
}

Recaptcha.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dispatch: state.dispatch
})

export default connect(mapStateToProps)(Recaptcha)
