import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { KYC_LOGIN_LOGOUT } from './actions/kycLogin'

import auth from './reducers/auth'
import app from './reducers/app'
import recaptcha from './reducers/recaptcha'
import signup from './reducers/signup'
import kycLogin from './reducers/kycLogin'
import kycAuth from './reducers/kycAuth'
import kycVerification from './reducers/kycVerification'

const appReducer = (history) =>
  combineReducers({
    auth,
    app,
    signup,
    form,
    recaptcha,
    kycLogin,
    kycAuth,
    kycVerification,
    router: history
  })

const rootReducer = (history) => (state, action) => {
  if (action.type === KYC_LOGIN_LOGOUT) {
    state = { router: state.router }
  }
  return appReducer(history)(state, action)
}

export default rootReducer
