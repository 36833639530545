import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Home from './components/Home'
import Login from './components/Login'
import VerifyEmail from './components/VerifyEmail'
import PrivateRoute from './components/PrivateRoute'
import KycFormPage from './containers/Kyc/FormPage'
import KycLoginPage from './containers/Kyc/LoginPage'

const App = () => {
  const auth = useSelector((state) => state.auth)
  const location = useLocation()

  return (
    <Routes location={ location }>
      <Route
        path='/kyc/login'
        element={ <KycLoginPage homePath='/kyc/form' /> }
      />
      <Route
        path='/kyc/form'
        element={
          <PrivateRoute auth={ auth }>
            <KycFormPage />
          </PrivateRoute>
        }
      />
      <Route
        path='/login'
        element={ auth.isAuthenticated ? <Home /> : <Login /> }
      />
      <Route
        path='/verify-email/:token'
        element={ <VerifyEmail /> }
      />
      <Route
        path='/'
        element={
          auth.isAuthenticated ? (
            <Home />
          ) : (
            <Navigate to='/login' state={ { from: location } } />
          )
        }
      />
    </Routes>
  )
}

export default App
