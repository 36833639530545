import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AppBar, Toolbar, IconButton, Avatar, Menu, MenuItem, Typography } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'

import { logout } from '../actions'

const LoggedIn = ({ auth, onLogoutClick }) => {
  const [ anchorEl, setAnchorEl ] = useState(null)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton onClick={ handleMenuOpen } style={ { padding: 0 } }>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ handleMenuClose }
        anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
        transformOrigin={ { vertical: 'top', horizontal: 'right' } }
      >
        <MenuItem>{auth.profile && auth.profile.email}</MenuItem>
        <MenuItem onClick={ onLogoutClick }>Sign out</MenuItem>
      </Menu>
    </div>
  )
}

LoggedIn.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const HeaderComponent = ({ dispatch, auth, appName }) => {
  return (
    <AppBar position='static'>
      <Toolbar>
        <Typography variant='h6' style={ { flexGrow: 1 } }>
          {appName}
        </Typography>
        {auth.isAuthenticated && (
          <LoggedIn auth={ auth } onLogoutClick={ () => dispatch(logout()) } />
        )}
      </Toolbar>
    </AppBar>
  )
}

HeaderComponent.propTypes = {
  appName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  dispatch: state.dispatch
})

export default connect(mapStateToProps)(HeaderComponent)
