import FormFieldEntry from './FormFieldEntry'

const getLocaleText = function (object, key) {
  if (!object[ key ]) {
    return ''
  }
  return object[ key ].en
}

export default function formFieldEntryFromJSONMetadata (JSONMetadata) {
  const type = JSONMetadata.type
  const fieldEntry = new FormFieldEntry()

  fieldEntry.id = JSONMetadata.id
  fieldEntry.type = type
  fieldEntry.label = getLocaleText(JSONMetadata, 'label')
  fieldEntry.validators = JSONMetadata.validators || []

  switch (type) {
    case 'input':
      fieldEntry.inputType = JSONMetadata.inputType
      break
    case 'caption':
      fieldEntry.value = getLocaleText(JSONMetadata, 'text')
      break
    case 'image':
      break
    case 'submetadata':
    case 'metadata':
    case 'group': {
      const children = JSONMetadata.items
      fieldEntry.children = children.map((child) => formFieldEntryFromJSONMetadata(child))
      break
    }
    default:
      console.log('Unexpected metadata type')
  }

  return fieldEntry
}
