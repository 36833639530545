import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HeaderComponent from './HeaderComponent'
import Main from './Main'

const mapStateToProps = (state) => ({
  auth: state.auth,
  location: state.router.location,
  appName: state.app.appName
})

const Home = ({ appName, auth, location, history }) => {
  useEffect(() => {
    if (location.search) {
      history.replace(location.pathname)
    }
  }, [ location, history ])

  return (
    <div>
      <HeaderComponent appName={ appName } auth={ auth } />
      <Main history={ history } location={ location } />
    </div>
  )
}

Home.propTypes = {
  appName: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(Home)
