import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Slide from '@mui/material/Slide'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'

import Form from './Form'
import StepBalls from './StepBalls'
import formFieldEntryFromJSONMetadata from './FormFieldEntryFactory'

const Stepper = ({ steps, customComponents }) => {
  const [ formIndex, setFormIndex ] = useState(0)
  const [ stepIndex, setStepIndex ] = useState(0)
  const [ subFormId, setSubFormId ] = useState(undefined)
  const [ subFormIndex, setSubFormIndex ] = useState(0)

  const totalSteps = steps.length
  const formValues = {}

  steps.forEach(step => {
    step.forms.forEach(form => {
      if (!form.componentName) {
        form.formFieldEntry = formFieldEntryFromJSONMetadata(form)
      } else if (form.choices) {
        Object.values(form.choices).forEach(choice => {
          choice.forms.forEach(subForm => {
            subForm.formFieldEntry = formFieldEntryFromJSONMetadata(subForm)
          })
        })
      }
    })
  })

  const getCurrentForm = () => {
    const form = steps[ stepIndex ].forms[ formIndex ]
    if (subFormId) {
      return form.choices[ subFormId ].forms[ subFormIndex ]
    }
    return form
  }

  const isFinalStepAndForm = () =>
    stepIndex + 1 === steps.length && formIndex + 1 === steps[ stepIndex ].forms.length

  const isFinalFormOfStep = () =>
    formIndex + 1 === steps[ stepIndex ].forms.length

  const isInitialStepAndForm = () =>
    stepIndex === 0 && formIndex === 0 && !subFormId

  const onNextSubForm = () => {
    const form = getCurrentForm()
    const selected = form.ref.getValue()
    form.choiceSelected = selected

    setSubFormId(selected)
    setSubFormIndex(0)
  }

  const onNext = () => {
    const { error } = getFormValidationErrors()
    if (error) return

    if (getCurrentForm().choices) {
      onNextSubForm()
      return
    }

    let newFormIndex = formIndex
    let newStepIndex = stepIndex
    let newSubFormIndex = subFormIndex
    let newSubFormId = subFormId

    if (subFormId) {
      newSubFormIndex += 1
      if (newSubFormIndex >= steps[ stepIndex ].forms[ formIndex ].choices[ subFormId ].forms.length) {
        newSubFormIndex = 0
        newSubFormId = undefined
        newFormIndex += 1
        if (newFormIndex >= steps[ stepIndex ].forms.length) {
          newFormIndex = 0
          newStepIndex += 1
        }
      }
    } else if (isFinalFormOfStep()) {
      newFormIndex = 0
      newStepIndex += 1
    } else {
      newFormIndex += 1
    }

    setFormIndex(newFormIndex)
    setStepIndex(newStepIndex)
    setSubFormId(newSubFormId)
    setSubFormIndex(newSubFormIndex)
  }

  const onBack = () => {
    let newFormIndex = formIndex
    let newStepIndex = stepIndex
    let newSubFormIndex = subFormIndex
    let newSubFormId = subFormId

    if (subFormId) {
      if (subFormIndex > 0) {
        newSubFormIndex -= 1
      } else {
        newSubFormId = null
      }
    } else if (isInitialStepAndForm()) {
      newStepIndex -= 1
      newFormIndex = steps[ newStepIndex ].forms.length - 1
      if (steps[ newStepIndex ].forms[ newFormIndex ].choices) {
        newSubFormId = steps[ newStepIndex ].forms[ newFormIndex ].choiceSelected
        newSubFormIndex = steps[ newStepIndex ].forms[ newFormIndex ].choices[ newSubFormId ].forms.length - 1
      }
    } else {
      newFormIndex -= 1
      if (steps[ stepIndex ].forms[ newFormIndex ].choices) {
        newSubFormId = steps[ stepIndex ].forms[ newFormIndex ].choiceSelected
        newSubFormIndex = steps[ stepIndex ].forms[ newFormIndex ].choices[ newSubFormId ].forms.length - 1
      }
    }

    setFormIndex(newFormIndex)
    setStepIndex(newStepIndex)
    setSubFormId(newSubFormId)
    setSubFormIndex(newSubFormIndex)
  }

  const getFormValidationErrors = () => {
    const form = getCurrentForm()
    if (form.componentName) {
      if (form.ref.validate) {
        return form.ref.validate()
      }
      return { error: false }
    }
    return form.formFieldEntry.getValidatedValues()
  }

  const getAllFormsValues = () => {
    const allFormsValues = {}

    steps.forEach(step => {
      step.forms.forEach(form => {
        if (form.formFieldEntry) {
          allFormsValues[ form.id ] = form.formFieldEntry.getValues()
        } else if (form.choices && form.choiceSelected) {
          form.choices[ form.choiceSelected ].forms.forEach(subForm => {
            allFormsValues[ subForm.id ] = subForm.formFieldEntry.getValues()
          })
        }
      })
    })

    return allFormsValues
  }

  const form = (() => {
    const currentForm = getCurrentForm()
    if (currentForm.componentName) {
      const CustomForm = customComponents[ currentForm.componentName ]
      return <CustomForm stepsValues={ getAllFormsValues() } ref={ (ref) => { currentForm.ref = ref; return null } } />
    } else {
      const { id } = currentForm
      return <Form key={ id } id={ id } form={ currentForm.formFieldEntry } values={ formValues[ id ] } stepsValues={ formValues } />
    }
  })()

  return (
    <Slide direction='left' in>
      <Card>
        <CardContent>
          <Box container spacing={ 3 }>
            <Box item xs={ 3 }>
              <Button
                color='secondary'
                variant='contained'
                onClick={ onBack }
                disabled={ isInitialStepAndForm() }
              >
                BACK
              </Button>
            </Box>
            <Box item xs={ 6 }>
              <StepBalls
                current={ stepIndex }
                total={ totalSteps }
                labels={ steps.map((step) => step.title) }
              />
            </Box>
            <Box item xs={ 3 }>
              {!isFinalStepAndForm() && (
                <Button
                  color='primary'
                  variant='contained'
                  onClick={ onNext }
                >
                  NEXT
                </Button>
              )}
            </Box>
            <Box item xs={ 12 }>
              {form}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Slide>
  )
}

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  customComponents: PropTypes.object.isRequired
}

export default Stepper
