import React, { useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import YesNoSwitch from '../../../components/YesNoSwitch'

const Consent = () => {
  const theme = useTheme()
  const [ checked, setChecked ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ error, setError ] = useState(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleConsentChange = (isChecked) => {
    setChecked(isChecked)
    setError(getError(isChecked))
  }

  const getError = (isChecked) => (isChecked ? null : 'Consent to Continue')

  return (
    <Box sx={ { width: '100%' } }>
      <Typography variant='h3' sx={ { mb: 2 } }>
        Data collected for Sedicii
      </Typography>
      {error && <Box sx={ { color: theme.palette.error.main, mb: 2 } }>{error}</Box>}
      <List sx={ { width: '100%', maxWidth: 360, margin: 'auto' } }>
        {[
          { icon: 'email', text: 'email' },
          { icon: 'contact_phone', text: 'phone number' },
          { icon: 'person', text: 'selfie' },
          { icon: 'featured_video', text: 'passport data' },
          { icon: 'format_list_bulleted', text: 'utility bill data' }
        ].map((item, index) => (
          <React.Fragment key={ item.text }>
            <ListItem>
              <ListItemIcon>
                <Icon>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={ item.text } />
            </ListItem>
            {index < 4 && <Divider />}
          </React.Fragment>
        ))}
      </List>
      <Box sx={ { mt: 2, textAlign: 'center' } }>
        <label>
          I consent and agree to the{' '}
          <Typography
            component='span'
            sx={ {
              color: 'blue',
              textDecoration: 'underline',
              '&:hover': { color: '#98999a', cursor: 'pointer' }
            } }
            onClick={ handleOpen }
          >
            Privacy Policy
          </Typography>
        </label>
        <Modal open={ open } onClose={ handleClose }>
          <Box
            sx={ {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '70%',
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[ 5 ],
              padding: theme.spacing(4),
              outline: 'none'
            } }
          >
            <Typography variant='h3' sx={ { mb: 2 } }>
              Privacy Policy
            </Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
            </Typography>
            <Box sx={ { textAlign: 'center', mt: 2 } }>
              <Button color='primary' variant='contained' onClick={ handleClose }>
                CLOSE
              </Button>
            </Box>
          </Box>
        </Modal>
        <YesNoSwitch
          color='primary'
          checked={ checked }
          onChange={ handleConsentChange }
        />
      </Box>
    </Box>
  )
}

export default Consent
