import {
  VERIFY_PHONE_REQUEST,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE
} from '../actions'

export default (state = {}, { type, payload, response, error }) => {
  switch (type) {

    case VERIFY_PHONE_REQUEST: {
      return {
        ...state,
        verifyPhoneError: null
      }
    }
    case VERIFY_PHONE_SUCCESS: {
      return {
        ...state,
        msisdnVerified: true
      }
    }
    case VERIFY_PHONE_FAILURE: {
      return {
        ...state,
        success: true,
        verifyPhoneError: error
      }
    }
    case VERIFY_EMAIL_REQUEST: {
      return {
        ...state,
        verifyEmailError: null
      }
    }
    case VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        emailVerified: true
      }
    }
    case VERIFY_EMAIL_FAILURE: {
      return {
        ...state,
        success: true,
        verifyEmailError: error
      }
    }
    default:
      return state
  }
}
