import moment from 'moment'

function validate ({ validator, value }) {
  const validationFunction = getValidationFunction(validator)
  const valid = validationFunction(value) || false

  return valid === false && validator.errorMessage.en
}

function getValidationFunction (validator) {
  switch (validator.type) {
    case 'required': return getRequiredValidationFunction(validator)
    case 'minLength': return getMinLengthValidationFunction(validator)
    case 'maxLength': return getMaxLengthValidationFunction(validator)
    case 'regex': return getRegexValidationFunction(validator)
    case 'date': return getDateValidationFunction(validator)
    default: return () => true
  }
}

function getRequiredValidationFunction () {
  return (value) => (value !== '' && value !== null && value !== undefined)
}

function getMinLengthValidationFunction (validator) {
  return (value) => (value && value.length >= validator.minLength)
}

function getMaxLengthValidationFunction (validator) {
  return (value) => (!value || value.length <= validator.maxLength)
}

function getRegexValidationFunction (validator) {
  return (value) => (value && value.match(new RegExp(validator.pattern)))
}

function getDateValidationFunction (validator) {
  return (value) => (value && moment(value).isValid())
}

export default {
  validate
}
