import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import Caption from './Caption'
import Image from './Image'
import GroupedItems from './GroupedItems'
import TextInput from './TextInput'
import DateInput from './DateInput'

const Item = ({ item }) => {
  const getItemByInputType = ({ item }) => {
    switch (item.inputType) {
      case 'text':
        return <TextInput item={ item } />
      case 'date':
        return <DateInput item={ item } />
      default:
        console.warn(`Dynamic forms: Input type ${ item.inputType } not valid`)
        return null
    }
  }

  const getItemByType = ({ item }) => {
    switch (item.type) {
      case 'input':
        return getItemByInputType({ item })
      case 'caption':
        return <Caption item={ item } />
      case 'image':
        return <Image item={ item } />
      case 'group':
        return <GroupedItems item={ item } />
      default:
        console.warn(`Dynamic forms: Type ${ item.type } not valid`)
        return null
    }
  }

  return (
    <Box item xs={ 12 }>
      {getItemByType({ item })}
    </Box>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired
}

export default Item
