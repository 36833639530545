import React, { useState } from 'react'
import Box from '@mui/material/Box'

import IdentityCard from './IdentityCard'
import drivingLicense from '../../../static/media/driving_license.png'
import idCard from '../../../static/media/id_card.png'
import passport from '../../../static/media/passport.png'

const ChooseForm = () => {
  const [ document, setDocument ] = useState(null)

  const identityCardSelected = (document) => {
    setDocument(document)
  }

  return (
    <Box container>
      <Box item xs={ 12 }>
        <h3>Select identity document</h3>
      </Box>
      <Box item xs={ 4 } />
      <Box item xs={ 4 }>
        <Box container spacing={ 3 }>
          <Box item xs={ 12 }>
            <IdentityCard documentName='Passport'
                          imageSrc={ passport }
                          selected={ document === 'passport' }
                          onClick={ () => identityCardSelected('passport') }
            />
          </Box>
          <Box item xs={ 12 }>
            <IdentityCard documentName='Driver&apos;s license'
                          imageSrc={ drivingLicense }
                          selected={ document === 'driversLicence' }
                          onClick={ () => identityCardSelected('driversLicence') }
            />
          </Box>
          <Box item xs={ 12 }>
            <IdentityCard documentName='ID card'
                          imageSrc={ idCard }
                          selected={ document === 'idCard' }
                          onClick={ () => identityCardSelected('idCard') }
            />
          </Box>
        </Box>
      </Box>
      <Box item xs={ 4 } />
    </Box>
  )
}

export default ChooseForm
