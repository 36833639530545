const SediciiColors = {
  primary1Color: '#e64210',
  primary2Color: '#050000',
  primary3Color: '#000000',
  accent1Color: '#e64210',
  accent2Color: '#ffffff',
  accent3Color: '#ff7440',
  textColor: '#050000',
  alternateTextColor: '#ffffff',
  secondaryColor: '#ff5722',
  secondaryTextColor: '#757575'
}

export default SediciiColors
