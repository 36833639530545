import jwtDecode from 'jwt-decode'

const ACCESS_TOKEN_ITEM = 'sedicii_access_token'

export function applyAccessToken (accessToken) {
  return window.localStorage.setItem(ACCESS_TOKEN_ITEM, accessToken)
}

export function getToken () {
  return window.localStorage.getItem(ACCESS_TOKEN_ITEM)
}

export function loadProfile () {
  const token = window.localStorage.getItem(ACCESS_TOKEN_ITEM)
  if (token) {
    return decode(token)
  }
}

export function isAuthenticated () {
  const token = window.localStorage.getItem(ACCESS_TOKEN_ITEM)
  return !!token && isTokenTimeValid(token)
}

function isTokenTimeValid (token) {
  const decoded = decode(token)
  return decoded && !!decoded.exp && new Date(decoded.exp * 1000) >= new Date()
}

function decode (token) {
  try {
    return jwtDecode(token)
  } catch (err) {
    console.error(err, 'Invalid token', token)
  }
}
