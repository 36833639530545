import React from 'react'
import Box from '@mui/material/Box'

import TermsAndConditionsItem from '../../../components/TermsAndConditionsItem'
import TermsAndConditionsJson from '../../../static/terms_and_conditions'

const TermsAndConditions = () => {
  const termsAndConditionsList = TermsAndConditionsJson.map((item, index) => (
    <TermsAndConditionsItem data={ item } key={ index } />
  ))

  return (
    <Box container>
      <Box item xs={ 12 }>
        <h3>Terms and conditions</h3>
        {termsAndConditionsList}
      </Box>
    </Box>
  )
}

export default TermsAndConditions
