import {
  KYC_VERIFICATION_FORM_LOADED_SUCCESS,
  KYC_VERIFICATION_STEPS_LOADED_SUCCESS,
  KYC_VERIFICATION_UPLOAD_REQUESTED,
  KYC_VERIFICATION_UPLOAD_SUCCESS,
  KYC_VERIFICATION_UPLOAD_ERROR
} from '../actions/kycVerification'

const defaultState = {
  steps: [],
  stepsLoadedSuccess: false,
  uploadingDocuments: false,
  uploadingError: false,
  uploadingSuccess: false
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case KYC_VERIFICATION_FORM_LOADED_SUCCESS: {
      return {
        ...state,
        steps: [ ...state.steps, payload ]
      }
    }
    case KYC_VERIFICATION_STEPS_LOADED_SUCCESS: {
      return {
        ...state,
        stepsLoadedSuccess: true
      }
    }
    case KYC_VERIFICATION_UPLOAD_REQUESTED:
      return {
        ...state,
        uploadingDocuments: true,
        uploadingError: false,
        uploadingSuccess: false
      }
    case KYC_VERIFICATION_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadingDocuments: false,
        uploadingSuccess: true
      }
    case KYC_VERIFICATION_UPLOAD_ERROR:
      return {
        ...state,
        uploadingDocuments: false,
        uploadingError: true
      }
    default:
      return state
  }
}
