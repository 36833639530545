import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import Item from './Item'

const GroupedItems = ({ item }) => {
  const items = item.children.map((child, index) => (
    <Box item xs key={ index }>
      <Item item={ child } />
    </Box>
  ))

  return (
    <Box container spacing={ 2 } justifyContent='center'>
      {items}
    </Box>
  )
}

GroupedItems.propTypes = {
  item: PropTypes.object.isRequired
}

export default GroupedItems
