import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, useMediaQuery, Box } from '@mui/material'
import { Notification } from 'react-admin'
import { connect } from 'react-redux'

const DashboardLayout = ({ children, isLoading }) => {
  const isSmall = useMediaQuery('(max-width:600px)')

  return (
    <Box display='flex' flexDirection='column' minHeight='100vh'>
      <Box
        sx={ {
          backgroundColor: isSmall ? '#fff' : '#edecec',
          display: 'flex',
          flex: '1 1 auto',
          overflowY: 'hidden',
          overflowX: 'scroll'
        } }
      >
        <Box sx={ { flex: 1, padding: isSmall ? '3em' : '2em' } }>{children}</Box>
      </Box>
      <Notification />
      {isLoading && (
        <CircularProgress
          color='inherit'
          size={ isSmall ? 20 : 30 }
          thickness={ 2 }
          sx={ {
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 2,
            zIndex: 1200
          } }
        />
      )}
    </Box>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.func, PropTypes.node ]),
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  isLoading: state.admin.loading > 0
})

export default connect(mapStateToProps)(DashboardLayout)
