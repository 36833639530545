import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'

import { uploadDocuments } from '../../../actions/kycVerification'

const SubmitDocuments = ({ stepsValues }) => {
  const dispatch = useDispatch()
  const kycVerification = useSelector((state) => state.kycVerification)

  const handleOnClick = () => {
    dispatch(uploadDocuments({ formValues: stepsValues }))
  }

  const handleOnClickFinish = () => {
    window.location.reload()
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Box mb={ 2 }>
        <h4>Press submit to upload your data</h4>
      </Box>

      {kycVerification.uploadingDocuments && (
        <Box mb={ 2 }>
          <CircularProgress size={ 60 } thickness={ 5 } />
        </Box>
      )}

      {kycVerification.uploadingSuccess && (
        <Box textAlign='center' mb={ 2 }>
          <Box
            sx={ {
              '& .MuiSvgIcon-root': {
                fontSize: '256px',
                color: '#27A014'
              }
            } }
          >
            <Icon>check_circle</Icon>
          </Box>
          <Box>Upload successful</Box>
        </Box>
      )}

      {kycVerification.uploadingError && (
        <Box textAlign='center' mb={ 2 }>
          <Box
            sx={ {
              '& .MuiSvgIcon-root': {
                fontSize: '256px',
                color: '#e64210'
              }
            } }
          >
            <Icon>error</Icon>
          </Box>
          <Box>Upload error. Try again</Box>
        </Box>
      )}

      <Box mt={ 2 }>
        {!kycVerification.uploadingSuccess ? (
          <Button
            variant='contained'
            color='primary'
            onClick={ handleOnClick }
            disabled={ kycVerification.uploadingDocuments }
          >
            Submit
          </Button>
        ) : (
          <Button
            variant='contained'
            color='primary'
            onClick={ handleOnClickFinish }
            disabled={ kycVerification.uploadingDocuments }
          >
            Finish
          </Button>
        )}
      </Box>
    </Box>
  )
}

SubmitDocuments.propTypes = {
  stepsValues: PropTypes.object.isRequired
}

export default SubmitDocuments
