import { LOGOUT_SUCCESS, TOKEN_VERIFY_SUCCESS, TOKEN_VERIFY_FAILURE } from '../actions'

import { loadProfile, isAuthenticated } from '../lib/auth-util'

const defaultState = {
  failed: false,
  isFetching: false,
  isAuthenticated: isAuthenticated(),
  profile: loadProfile()
}

export default (state = defaultState, { type, payload, response }) => {
  switch (type) {
    case TOKEN_VERIFY_FAILURE: {
      return {
        ...state,
        loginFailed: true
      }
    }
    case TOKEN_VERIFY_SUCCESS: {
      return {
        isAuthenticated: true,
        loginFailed: false,
        profile: response.profile
      }
    }
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false
      })
    default:
      return state
  }
}
