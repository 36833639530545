import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import ListItemIcon from '@mui/material/ListItemIcon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

function getIcon (icon) {
  return require(`../../static/media/${ icon }.png`)
}

const TermsAndConditionsItem = ({ data }) => {
  const theme = useTheme()
  const [ open, setOpen ] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Box
      onClick={ handleOpen }
      sx={ {
        backgroundColor: theme.palette.background.default,
        width: '80%',
        padding: '5px',
        margin: '5px',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
          cursor: 'pointer'
        }
      } }
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <img src={ getIcon(data.icon) } alt={ data.icon } />
          </ListItemIcon>
          <Typography variant='h6' sx={ { color: theme.palette.text.primary } }>
            {data.title}
          </Typography>
        </ListItem>
      </List>
      <Modal open={ open } onClose={ handleClose }>
        <Box
          sx={ {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[ 5 ],
            padding: theme.spacing(4),
            outline: 'none'
          } }
        >
          <Box dangerouslySetInnerHTML={ { __html: data.html_content } } />
          <Box sx={ { textAlign: 'center', marginTop: theme.spacing(2) } }>
            <Button color='primary' variant='contained' onClick={ handleClose }>
              CLOSE
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

TermsAndConditionsItem.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    html_content: PropTypes.string.isRequired
  }).isRequired
}

export default TermsAndConditionsItem
