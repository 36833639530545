import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

const IdentityCard = ({ documentName, onClick, selected, imageSrc }) => {
  return (
    <Paper
      onClick={ onClick }
      elevation={ 5 }
      square={ false }
      sx={ {
        padding: '10pt',
        borderRadius: '5px',
        backgroundColor: selected ? '#e0e0e0' : 'transparent',
        '&:hover': {
          cursor: 'pointer'
        }
      } }
    >
      <Box sx={ { display: 'flex', gap: 2 } }>
        <Box sx={ { flex: 1 } }>
          <img src={ imageSrc } alt={ documentName } style={ { width: '100%' } } />
        </Box>
        <Box sx={ { flex: 2 } }>
          <Typography
            variant='subtitle1'
            sx={ {
              fontSize: '1.1em',
              mt: 1,
              color: selected ? '#000000' : 'inherit'
            } }
          >
            Verify your identity with
          </Typography>
          <Typography
            variant='h5'
            sx={ {
              fontSize: '1.4em',
              mt: 1,
              color: selected ? '#000000' : 'inherit'
            } }
          >
            {documentName}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

IdentityCard.propTypes = {
  documentName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string.isRequired
}

export default IdentityCard
