import { CALL_API } from './middleware/api'
import { applyAccessToken, isAuthenticated, loadProfile } from './lib/auth-util'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

function requestLogout () {
  return {
    type: LOGOUT_REQUEST
  }
}

function receiveLogout () {
  return {
    type: LOGOUT_SUCCESS
  }
}

export function logout () {
  return dispatch => {
    dispatch(requestLogout())
    window.localStorage.removeItem('sedicii_id_token')
    dispatch(receiveLogout())
  }
}

export const VERIFY_PHONE_REQUEST = 'VERIFY_PHONE_REQUEST'
export const VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS'
export const VERIFY_PHONE_FAILURE = 'VERIFY_PHONE_FAILURE'

export function verifyPhone ({ userId, smsCode }) {
  return {
    [ CALL_API ]: {
      endpoint: `user/${ userId }`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ smsCode }),
      authenticated: false,
      types: [ VERIFY_PHONE_REQUEST, VERIFY_PHONE_SUCCESS, VERIFY_PHONE_FAILURE ]
    }
  }
}

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE'

export function verifyEmail ({ token, recaptchaResponse }) {
  return {
    [ CALL_API ]: {
      endpoint: `user/token_verify_${ token }`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ recaptchaResponse }),
      authenticated: false,
      types: [ VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILURE ]
    }
  }
}

export const UPDATE_RECAPTCHA = 'UPDATE_RECAPTCHA'

export function updateRecaptcha (response) {
  return {
    type: UPDATE_RECAPTCHA,
    response
  }
}

export const TOKEN_VERIFY_SUCCESS = 'TOKEN_VERIFY_SUCCESS'
export const TOKEN_VERIFY_FAILURE = 'TOKEN_VERIFY_FAILURE'

export function verifyAccessToken (accessToken) {
  return dispatch => {
    applyAccessToken(accessToken)
    if (isAuthenticated()) {
      dispatch({ type: TOKEN_VERIFY_SUCCESS, response: { profile: loadProfile() } })
    } else {
      dispatch({ type: TOKEN_VERIFY_FAILURE })
    }
  }
}
