import React from 'react'
import PropTypes from 'prop-types'
import { List, Datagrid, TextField, DateField } from 'react-admin'
import { Chip, Avatar } from '@mui/material'
import FontDownloadIcon from '@mui/icons-material/FontDownload'
import { green, amber } from '@mui/material/colors'

const eventTypes = {
  identity_access_token_created: 'Created',
  identity_access_token_used: 'Used'
}

const eventTypeIcons = {
  identity_access_token_created: 'playlist_add',
  identity_access_token_used: 'playlist_add_check'
}

const policies = {
  verification: 'Verification',
  data: 'Data',
  unknown: 'Unknown'
}

const policyColors = {
  verification: amber[ 200 ],
  data: green[ 200 ]
}

const BillingEvents = (props) => {
  return (
    <List { ...props } sort={ { field: 'timestamp', order: 'DESC' } }>
      <Datagrid>
        <TextField label='IdP' source='idpId' />
        <EventTypeField label='Event Type' source='type' />
        <PolicyField label='Policy' source='identityAccessPolicy' />
        <DateField label='Consent Date' source='timestamp' showTime />
      </Datagrid>
    </List>
  )
}

const PolicyField = ({ source, record = {} }) => {
  if (!record[ source ]) {
    return null
  }

  const chips = record[ source ].map((policy, index) => (
    <Chip
      key={ index }
      style={ { margin: 4 } }
      sx={ { backgroundColor: policyColors[ policy ] || 'grey' } }
    >
      {policies[ policy ] || 'Unknown'}
    </Chip>
  ))

  return <div style={ { display: 'flex', flexWrap: 'wrap' } }>{chips}</div>
}

PolicyField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired
}

const EventTypeField = ({ record = {} }) => {
  const icon = <FontDownloadIcon>{eventTypeIcons[ record.type ] || 'error'}</FontDownloadIcon>

  return (
    <Chip>
      <Avatar>{icon}</Avatar>
      {eventTypes[ record.type ] || 'unknown'}
    </Chip>
  )
}

EventTypeField.propTypes = {
  record: PropTypes.object
}

export default BillingEvents
