import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

import { verifyEmail } from '../actions'
import Recaptcha from './Recaptcha'

import logo from '../static/media/sedicii-logo.png'

const VerifyEmail = ({ match }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const signup = useSelector((state) => state.signup)
  const recaptchaResponse = useSelector((state) => state.recaptcha.response)

  const handleVerify = () => {
    if (recaptchaResponse && recaptchaResponse.length) {
      const { token } = match.params
      dispatch(
        verifyEmail({
          token,
          recaptchaResponse
        })
      )
    }
  }

  const getErrorFeedback = () => {
    if (!signup.verifyEmailError) {
      return null
    }

    const message = (() => {
      switch (signup.verifyEmailError.statusCode) {
        case 404:
          return 'The account being verified no longer exists.'
        case 410:
          return 'The verification link has expired. Click \'Resend\' to request a new verification link.'
        default:
          return 'An unexpected error occurred. Please try again later.'
      }
    })()

    return <Box sx={ { color: theme.palette.primary.main } }>{message}</Box>
  }

  const renderRecaptcha = () => {
    const errorFeedback = getErrorFeedback()

    return (
      <form>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box sx={ { padding: '1rem', height: '5rem' } }>
            <img alt='Sedicii Logo' src={ logo } style={ { height: '100%' } } />
          </Box>
          <Box sx={ { padding: '1rem' } }>
            <span>Before you can use Sedicii, we need to verify your email address</span>
          </Box>
          <Box sx={ { padding: '1rem' } }>
            <Recaptcha onChange={ handleVerify } />
          </Box>
          {errorFeedback}
        </Box>
      </form>
    )
  }

  const renderVerificationComplete = () => (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Box sx={ { padding: '1rem', height: '5rem' } }>
        <img alt='Sedicii Logo' src={ logo } style={ { height: '100%' } } />
      </Box>
      <Box sx={ { padding: '1rem' } }>
        <span>Your email address has been verified</span>
      </Box>
    </Box>
  )

  const renderVerificationForm = () =>
    signup.emailVerified ? renderVerificationComplete() : renderRecaptcha()

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={ { height: '100vh', textAlign: 'center' } }
    >
      <Box sx={ { width: { xs: '90%', md: '50%', lg: '33%' } } }>
        <Paper sx={ { padding: '1rem' } }>{renderVerificationForm()}</Paper>
      </Box>
    </Box>
  )
}

VerifyEmail.propTypes = {
  match: PropTypes.object.isRequired
}

export default VerifyEmail
