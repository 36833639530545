import {
  KYC_LOGIN_RESET,
  KYC_LOGIN_FORM_ERROR,
  KYC_LOGIN_FORM_SUBMIT,
  KYC_LOGIN_MFA_PENDING,
  KYC_LOGIN_MFA_SUCCESS,
  KYC_LOGIN_MFA_FAILURE,
  KYC_LOGIN_SYNC
} from '../actions/kycLogin'

const defaultState = {
  phase: 'FORM',
  loginFailed: false,
  mnemonic: ''
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case KYC_LOGIN_SYNC: {
      if (!payload.isAuthenticated) {
        return defaultState
      }
      return state
    }
    case KYC_LOGIN_RESET: {
      return {
        ...state,
        phase: 'FORM',
        loginFailed: false
      }
    }
    case KYC_LOGIN_FORM_ERROR: {
      return {
        ...state,
        phase: 'FORM',
        loginFailed: true
      }
    }
    case KYC_LOGIN_FORM_SUBMIT: {
      return {
        ...state,
        phase: 'FORM_SUBMITTING'
      }
    }
    case KYC_LOGIN_MFA_PENDING: {
      return {
        ...state,
        phase: 'PENDING',
        loginFailed: false,
        mnemonic: payload.idpVerificationMnemonic
      }
    }
    case KYC_LOGIN_MFA_SUCCESS: {
      return {
        ...state,
        phase: 'SUCCESS'
      }
    }
    case KYC_LOGIN_MFA_FAILURE: {
      return {
        ...state,
        phase: 'ERROR'
      }
    }
    default:
      return state
  }
}
