import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import Item from './Item'

const Form = ({ form }) => {

  const itemComponents = form.children.map((item, index) => (
    <Item item={ item } key={ `${ form.id }_${ index }` } />
  ))

  return (
    <Box item xs={ 12 }>
      <h3>{form.label}</h3>
      <form>{itemComponents}</form>
    </Box>
  )
}

Form.propTypes = {
  form: PropTypes.object.isRequired
}

export default Form
