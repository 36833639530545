import { UPDATE_RECAPTCHA } from '../actions'

export default (state = {}, { type, response }) => {
  switch (type) {
    case UPDATE_RECAPTCHA: {
      return {
        response
      }
    }
    default:
      return state
  }
}
