import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch'

const YesNoSwitch = ({ onChange, ...others }) => {
  const [ checked, setChecked ] = useState(false)

  const handleChange = (checked) => {
    setChecked(checked)
    if (onChange) {
      onChange(checked)
    }
  }

  const text = checked ? 'Yes' : 'No'

  return (
    <div>
      <Switch { ...others } checked={ checked } onChange={ (e, checked) => handleChange(checked) } />
      <span>{text}</span>
    </div>
  )
}

YesNoSwitch.propTypes = {
  onChange: PropTypes.func
}

export default YesNoSwitch
