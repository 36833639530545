import validatorService from './services/validatorService'

const createFormFieldEntry = () => {
  const id = ''
  const type = null
  const inputType = null
  const label = ''
  let value = null
  const validators = []
  const children = []
  let fieldRef = null

  const setValue = (newValue) => {
    value = newValue
  }

  const getValues = () => {
    if (children.length) {
      return children.reduce((acc, formField) => {
        if (formField.type !== 'caption') {
          Object.assign(acc, formField.getValues())
        }
        return acc
      }, {})
    }

    return { [ id ]: value }
  }

  const getValidationError = () => {
    if (children.length) {
      return children.reduce((acc, child) => {
        if (!acc) {
          const { error } = child.getValidatedValues()
          if (error) {
            acc = error
          }
        }
        return acc
      }, null)
    }

    const { error } = getValidatedValue()
    return error || null
  }

  const getValidatedValues = () => {
    const error = getValidationError()
    const values = error === null ? getValues() : null
    return { values, error }
  }

  const getValidatedValue = () => {
    return { value, error: validate() }
  }

  const validate = () => {
    for (const validator of validators) {
      const error = validatorService.validate({ validator, value })
      if (error) {
        onFailedValidation(error)
        return error
      }
    }
    onSuccessfulValidation()
    return null
  }

  const setFieldRef = (ref) => {
    fieldRef = ref
  }

  const onFailedValidation = (error) => {
    if (fieldRef) fieldRef.setError(error)
  }

  const onSuccessfulValidation = () => {
    if (fieldRef) fieldRef.setError(null)
  }

  return {
    id,
    type,
    inputType,
    label,
    value,
    validators,
    children,
    fieldRef,
    setValue,
    getValues,
    getValidationError,
    getValidatedValues,
    getValidatedValue,
    validate,
    setFieldRef,
    onFailedValidation,
    onSuccessfulValidation
  }
}

export default createFormFieldEntry
