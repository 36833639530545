import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { login } from '../../../actions/kycLogin'
import LoginPanel from './LoginPanel'

const LoginPage = ({ homePath, handleAlreadyLogged, handleFinishProcess }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const kycLogin = useSelector((state) => state.kycLogin)
  const isAuthenticated = useSelector((state) => state.kycAuth.isAuthenticated)

  const { control, handleSubmit, formState: { errors, isSubmitting } } = useForm({ mode: 'onChange' })

  useEffect(() => {
    if (isAuthenticated) {
      const { from } = location.state || { from: { pathname: homePath } }
      handleAlreadyLogged({ to: from.pathname })
    }
  }, [ isAuthenticated, handleAlreadyLogged, homePath, location.state ])

  const onSubmit = (data) => {
    dispatch(login(data))
  }

  const renderLoginContainer = () => (
    <LoginPanel show={ [ 'FORM', 'FORM_SUBMITTING' ].includes(kycLogin.phase) }>
      <form onSubmit={ handleSubmit(onSubmit) } style={ { display: 'flex', flexDirection: 'column', width: '100%' } }>
        {kycLogin.loginFailed && (
          <Box sx={ { color: '#e64210', padding: '15pt', textAlign: 'center' } }>
            <span>Login failed</span>
          </Box>
        )}
        <Box sx={ { marginBottom: '1rem' } }>
          <Controller
            name="email"
            control={ control }
            rules={ { required: 'Valid email required', validate: (value) => validator.isEmail(value) || 'Invalid email' } }
            render={ ({ field }) => (
              <TextField
                { ...field }
                label="Email"
                fullWidth
                error={ !!errors.email }
                helperText={ errors.email ? errors.email.message : '' }
                autoFocus
              />
            ) }
          />
        </Box>
        <Box sx={ { marginBottom: '1rem' } }>
          <Controller
            name="password"
            control={ control }
            rules={ { required: 'Password required' } }
            render={ ({ field }) => (
              <TextField
                { ...field }
                label="Password"
                type="password"
                fullWidth
                error={ !!errors.password }
                helperText={ errors.password ? errors.password.message : '' }
              />
            ) }
          />
        </Box>
        {kycLogin.phase === 'FORM_SUBMITTING' && (
          <Box sx={ { display: 'flex', justifyContent: 'center', margin: '10pt' } }>
            <CircularProgress size={ 60 } thickness={ 5 } />
          </Box>
        )}
        <Box sx={ { display: 'flex', justifyContent: 'flex-end', marginTop: '10pt' } }>
          <Button
            variant="contained"
            disabled={ isSubmitting }
            type="submit"
            color="primary"
          >
            Sign In
          </Button>
        </Box>
      </form>
    </LoginPanel>
  )

  const renderPendingContainer = () => (
    <LoginPanel show={ kycLogin.phase === 'PENDING' }>
      <Box sx={ { color: '#777777', textAlign: 'center', paddingY: '1rem' } }>
        <Typography variant="h6">Authorize web login</Typography>
        <Typography variant="h3" color="error" sx={ { marginTop: '1rem' } }>{kycLogin.mnemonic}</Typography>
        <CircularProgress size={ 60 } thickness={ 5 } sx={ { marginY: '25pt' } } />
        <Typography variant="body2">Check this message on your mobile device and authorize to proceed</Typography>
      </Box>
    </LoginPanel>
  )

  const renderErrorContainer = () => (
    <LoginPanel show={ kycLogin.phase === 'ERROR' }>
      <Box sx={ { color: '#e64210', textAlign: 'center' } }>
        <Typography variant="h4">TIMED OUT</Typography>
        <Icon sx={ { fontSize: '256px', marginY: '20pt' } }>error</Icon>
        <Button variant="contained" onClick={ handleFinishProcess } color="primary">
          OK
        </Button>
      </Box>
    </LoginPanel>
  )

  return (
    <>
      {[ 'FORM', 'FORM_SUBMITTING' ].includes(kycLogin.phase) && renderLoginContainer()}
      {kycLogin.phase === 'PENDING' && renderPendingContainer()}
      {kycLogin.phase === 'ERROR' && renderErrorContainer()}
    </>
  )
}

LoginPage.propTypes = {
  homePath: PropTypes.string,
  handleFinishProcess: PropTypes.func.isRequired,
  handleAlreadyLogged: PropTypes.func.isRequired
}

LoginPage.defaultProps = {
  homePath: '/'
}

export default LoginPage
