import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

import { Stepper } from '../../../components/DynamicForms'
import Consent from './Consent'
import TermsAndConditions from './TermsAndConditions'
import SubmitDocuments from './SubmitDocuments'
import ChooseForm from './ChooseForm'

import { logout } from '../../../actions/kycLogin'
import { loadSteps } from '../../../actions/kycVerification'

import logo from '../../../static/media/sedicii-logo.png'

const FormPage = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.kycAuth.isAuthenticated)
  const steps = useSelector((state) => state.kycVerification.steps)
  const stepsLoadedSuccess = useSelector((state) => state.kycVerification.stepsLoadedSuccess)

  useEffect(() => {
    if (!stepsLoadedSuccess) {
      dispatch(loadSteps())
    }
  }, [ stepsLoadedSuccess, dispatch ])

  if (!stepsLoadedSuccess) {
    return null
  }

  const customComponents = {
    ChooseForm,
    Consent,
    TermsAndConditions,
    SubmitDocuments
  }

  return (
    <Box sx={ { width: '100%' } }>
      <AppBar position='static' color='inherit'>
        <Toolbar>
          <Box component='img' src={ logo } alt='Sedicii Logo' sx={ { height: '32px' } } />
          <Box sx={ { flex: 1 } } />
          {isAuthenticated && (
            <Button color='inherit' onClick={ () => dispatch(logout()) }>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Box sx={ { mt: 6, display: 'flex', justifyContent: 'center' } }>
        <Paper elevation={ 4 } sx={ { width: '50vw', p: 4 } }>
          <Stepper steps={ steps } customComponents={ customComponents } />
        </Paper>
      </Box>
    </Box>
  )
}

FormPage.propTypes = {
  isAuthenticated: PropTypes.bool,
  stepsLoadedSuccess: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.object)
}

export default FormPage
