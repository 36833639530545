import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import StepBall from './StepBall'

const StepBalls = ({ current, total, labels }) => {
  const dots = []

  for (let i = 0; i < total; i++) {
    dots.push(<StepBall label={ labels[ i ] } filled={ i === current } key={ i } />)
  }

  return (
    <Box sx={ { whiteSpace: 'nowrap' } }>
      {dots}
    </Box>
  )
}

StepBalls.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default StepBalls
