import { getToken } from '../lib/auth-util'
import { parseJSON } from '../lib/json-util'

const Symbol = require('es6-symbol')

function callApi ({ endpoint, authenticated, ...fetchConfig }) {
  const token = getToken()
  const config = { ...fetchConfig }

  if (authenticated) {
    if (!token) {
      // Fail gracefully
      return Promise.resolve(null)
    }
    config.headers = { ...(config.headers || {}), 'Authorization': `Bearer ${ token }` }
  }

  return window.fetch(`/api/${ endpoint }`, config)
  .then(response => response.text().then(text => ({ text, response })))
  .then(({ text, response }) => {
    if (!response.ok) {
      return Promise.reject(text)
    }
    return text
  })
  .catch(err => {
    return Promise.reject(err)
  })
}

export const CALL_API = Symbol('Call API')

export default store => next => action => {
  const callAPI = action[ CALL_API ]

  // This middleware is not for every action
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  const { types, authenticated, ...rest } = callAPI
  delete rest.endpoint
  delete rest.method
  const [ requestType, successType, failureType ] = types

  next({ type: requestType })
  return callApi(callAPI).then(
    response => {
      const parsedResponse = parseJSON(response)
      next({
        response: parsedResponse,
        authenticated,
        type: successType,
        ...rest
      })
    },
    error => next({
      error: parseJSON(error) || error,
      type: failureType
    })
  )
}
