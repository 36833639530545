import React from 'react'
import { Admin, Resource } from 'react-admin'
import restClient from '../lib/rest-client'
import BillingEvents from './BillingEvents'
import DashboardLayout from './DashboardLayout'
import { themeV0 } from './theme'

const Dashboard = () => (
  <Admin appLayout={ DashboardLayout } theme={ themeV0 } dataProvider={ restClient('/api') } title='RP Dashboard'>
    <Resource name='billing/events' list={ BillingEvents } options={ { label: 'Billing Events' } } />
  </Admin>
)

export default Dashboard
