import PropTypes from 'prop-types'
import React from 'react'
import URL from 'url-parse'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'

import { verifyAccessToken } from '../actions'
import logo from '../static/media/sedicii-logo.png'

const Login = ({ dispatch, auth }) => {
  const redirUrl = new URL(window.location.href)
  redirUrl.pathname = '/login'

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm({ mode: 'onChange' })

  const onSubmit = (data) => {
    dispatch(verifyAccessToken(data.accessToken))
  }

  const errorFeedback = auth.loginFailed ? (
    <Box display='flex' justifyContent='center' sx={ { color: '#e64210', padding: '15pt' } }>
      <span>Login failed</span>
    </Box>
  ) : null

  return (
    <Box display='flex' justifyContent='center' sx={ { margin: '4rem', marginTop: '10rem' } }>
      <Box sx={ { width: { xs: '100%', sm: '33%' }, maxWidth: 288, minWidth: 288 } }>
        <Fade in timeout={ 1000 }>
          <Paper sx={ { padding: '15pt' } }>
            <Box display='flex' justifyContent='center' flexDirection='column'>
              <Box display='flex' justifyContent='center' sx={ { padding: '1rem', height: '5rem' } }>
                <img alt='Sedicii Logo' src={ logo } style={ { height: '100%' } } />
              </Box>
              <form
                id='loginForm'
                onSubmit={ handleSubmit(onSubmit) }
                method='POST'
                style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
              >
                {errorFeedback}
                <Box sx={ { marginY: '1.5rem', paddingX: '1rem' } }>
                  <textarea
                    { ...register('accessToken', { required: 'Token required' }) }
                    placeholder='Access Token'
                    rows={ 4 }
                    style={ { width: '100%' } }
                  />
                  {errors.accessToken && (
                    <Box sx={ { color: '#e64210' } }>
                      {errors.accessToken.message}
                    </Box>
                  )}
                </Box>
                <Box display='flex' justifyContent='flex-end' sx={ { marginTop: '10pt' } }>
                  <Button
                    variant='contained'
                    disabled={ !isValid || isSubmitting }
                    type='submit'
                    color='primary'
                  >
                    Sign In
                  </Button>
                </Box>
              </form>
            </Box>
          </Paper>
        </Fade>
      </Box>
    </Box>
  )
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps)(Login)
