import { createTheme } from '@mui/material/styles'
import SediciiColors from './SediciiColors'

export const theme = createTheme({
  palette: {
    primary: {
      main: SediciiColors.primary1Color,
      dark: SediciiColors.primary1Color,
      contrastText: SediciiColors.alternateTextColor
    },
    secondary: {
      main: '#d7d7d7'
    },
    text: {
      primary: SediciiColors.primary1Color
    },
    error: {
      main: SediciiColors.primary1Color
    }
  }
})

export const themeV0 = createTheme({
  palette: {
    primary: {
      main: SediciiColors.primary1Color
    },
    secondary: {
      main: SediciiColors.secondaryColor
    },
    background: {
      default: '#fafafa'
    },
    text: {
      primary: SediciiColors.primary1Color,
      secondary: SediciiColors.secondaryTextColor
    }
  }
})
