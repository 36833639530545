import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

const StepBall = ({ label, filled }) => {
  const theme = useTheme()

  return (
    <Box
      sx={ {
        position: 'relative',
        width: '60px',
        display: 'inline-block',
        textAlign: 'center',
        '&:not(:last-child)::after': {
          position: 'absolute',
          top: '62%',
          content: '""',
          display: 'inline-block',
          width: '45px',
          height: '9px',
          borderTop: `2px solid ${ theme.palette.text.primary }`
        }
      } }
    >
      <Typography
        variant='caption'
        sx={ {
          fontSize: '0.7em',
          fontWeight: 'bold'
        } }
      >
        {label}
      </Typography>
      <Box
        sx={ {
          display: 'inline-block',
          width: '12pt',
          height: '12pt',
          borderRadius: '50%',
          border: `2px solid ${ theme.palette.text.primary }`,
          backgroundColor: filled ? '#e64210' : 'transparent'
        } }
      />
    </Box>
  )
}

StepBall.propTypes = {
  label: PropTypes.string.isRequired,
  filled: PropTypes.bool.isRequired
}

export default StepBall
