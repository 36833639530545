import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history'
import { save, load } from 'redux-localstorage-simple'

import createRootReducer from './reducer'
import { api } from './middleware'
import { loginSync } from './actions/kycLogin'

const browserHistory = createBrowserHistory()

const { createReduxHistory, routerMiddleware: reduxRouterMiddleware, routerReducer } = createReduxHistoryContext({
  history: browserHistory,
  savePreviousLocations: 1
})

const getMiddleware = () => {
  const middlewares = [
    thunkMiddleware,
    api,
    reduxRouterMiddleware,
    save({ states: [ 'kycAuth' ], namespace: 'kyc' })
  ]
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger())
  }
  return applyMiddleware(...middlewares)
}

const store = createStore(
  createRootReducer(routerReducer),
  load({ states: [ 'kycAuth' ], namespace: 'kyc' }),
  composeWithDevTools(getMiddleware())
)

window.addEventListener('storage', function (e) {
  if (e.key !== 'kyc_kycAuth') {
    return
  }

  const state = (e.newValue && JSON.parse(e.newValue)) || {}

  store.dispatch(loginSync(state))
})

export const history = createReduxHistory(store)

export default store
