import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { HistoryRouter as ConnectedRouter } from 'redux-first-history/rr6'
import history from './history'
import store from './store'
import registerServiceWorker from './registerServiceWorker'
import App from './App'

import './index.css'

render(
  <Provider store={ store }>
    {/* Use the HistoryRouter from redux-first-history */}
    <ConnectedRouter history={ history }>
      <App /> {/* Only render App component here */}
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

registerServiceWorker()
