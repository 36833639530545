import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import FormHelperText from '@mui/material/FormHelperText'

const TextInput = ({ item }) => {
  const [ error ] = useState(null)

  const handleChange = (value) => {
    item.setValue(value)
  }

  const formHelperTextId = `${ item.id }-helper-text`

  return (
    <FormControl aria-describedby={ formHelperTextId } error={ !!error } sx={ { width: '100%' } }>
      <InputLabel htmlFor={ item.id }>{item.label}</InputLabel>
      <Input
        id={ item.id }
        defaultValue={ item.value }
        onChange={ (event) => handleChange(event.target.value) }
      />
      {error && <FormHelperText id={ formHelperTextId }>{error}</FormHelperText>}
    </FormControl>
  )
}

TextInput.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired
  }).isRequired
}

export default TextInput
