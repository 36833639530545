import React from 'react'
import PropTypes from 'prop-types'

const Caption = ({ item }) => (
  <div>
    <label id={ item.id }>{item.value}</label>
  </div>
)

Caption.propTypes = {
  item: PropTypes.object.isRequired
}

export default Caption
